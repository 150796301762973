// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiHost: 'https://api.testing.business.bepark.eu',
  apiVersion: 'api/v4',
  apiClientId: '2',
  apiClientSecret: 'WEQuv9gK7aXzuboEl8Z9nUcLALdBxf8AsjXbLSOG',
  apiAppClientId: '1',
  apiAppClientSecret: 'dtq1KiLxQ7ND4Yia2dua9gTsds5T2ejrxloCKLmS',
  localizationMaximumAllowedDistanceToOpenGate: 100,
  oAuthHost: 'https://auth.testing.izix.eu',
  oAuthClientId: '9b81f126-a2cf-45cf-ae19-4d6e28eac261',
  nativeOAuthRedirectUri:  'https://web.testing.business.bepark.eu/authentication/mobile-redirect',
  pwaOAuthRedirectUri: 'https://testing.app.business.bepark.eu/login'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js'
import 'zone.js/testing';

