import { AlertService } from 'src/app/services/alert.service';
import {Component} from '@angular/core';
import { ModalController, NavController, NavParams} from '@ionic/angular';
import {TranslationService} from '../../services/translation.service';
import {ToastService} from '../../services/toast.service';
import {BarcodeFormat} from '@zxing/library';
import {ApiService, BesaasResponse} from '../../services/api.service';
import {route} from '../../../environments/route';
import {LoginService} from '../../login/services/login.service';
import {OrganisationService} from '../../tabs/services/organisation.service';
import {HelpdeskService} from '../../services/helpdesk.service';
import {ErrorManagerServiceService} from '../../services/errorManagerService.service';

@Component({
  selector: 'app-qr-code-scanner',
  templateUrl: './qr-code-scanner.page.html',
})
export class QrCodeScannerPage  {

  showLoader = false;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.QR_CODE,
  ];

  constructor(
      public alertService: AlertService,
      public toastService: ToastService,
      public navController: NavController,
      public loginService: LoginService,
      public modalController: ModalController,
      private organisationService: OrganisationService,
      private apiService: ApiService,
      private translationService: TranslationService,
      private helpdeskService: HelpdeskService,
      private errorManagerServiceService: ErrorManagerServiceService,
      public navParams: NavParams
  ) {}

  public handleQrSuccess(barcode: string) {
    this.showLoader = true;
    this.modalController.dismiss();
    this.loginService.loginWithCode(barcode, (response) => {
      this.organisationService.getOrganisations().subscribe((organisationResponse: BesaasResponse) => {
        this.organisationService.switchOrganisation(organisationResponse.data[0].id);
        this.toastService.presentToast(this.translations.login_success);
        this.showLoader = false;
        this.navController.navigateForward(route.access);
      }, (organisationResponse: BesaasResponse) => {
        this.showLoader = false;
        this.alertService.present(this.translations.error_fetch_organizations);
        this.errorManagerServiceService.handle(organisationResponse, false);
      });
    }, (response) => {
      this.modalController.dismiss();
      this.showLoader = false;
      switch (response.status) {
        case 404:
          this.alertService.present(this.translations.error_404);
          break;
        case 419:
          this.alertService.present(this.translations.error_419);
          break;
        default:
          this.alertService.uncaughtErrorAlert(response)
      }
    });
  }

  translations = {
    login_with_qr: '',
    login_success: '',
    error_404: '',
    error_419: '',
    error_fetch_organizations: '',
  };

  ionViewDidEnter(): void {
    this.translationService.loadTranslation('login', this.translations);
  }

  public dismissModal() {
    this.modalController.dismiss();
  }
}
