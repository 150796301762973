import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { StorageService } from '../../services/storage.service';
import {ApiService, BesaasResponse} from '../../services/api.service';
import { ModalController, NavController, Platform} from '@ionic/angular';
import { TranslationService } from '../../services/translation.service';
import { Barcode, BarcodeScanner, BarcodeFormat } from '@capacitor-mlkit/barcode-scanning';
import { nanoid } from "nanoid";
import { Browser } from '@capacitor/browser';
import { OAuth2AuthenticateOptions, OAuth2Client } from "@byteowls/capacitor-oauth2";
import { route } from "../../../environments/route";
import { ToastService } from "../../services/toast.service";
import { OrganisationService } from "../../tabs/services/organisation.service";
import { HelpdeskService } from "../../services/helpdesk.service";
import { ErrorManagerServiceService } from "../../services/errorManagerService.service";
import { LoaderService } from "../../services/loader.service";
import { AlertService } from 'src/app/services/alert.service';

@Injectable({
  providedIn: 'root'
})

export class LoginService {

  public scanSubscriber;
  barcodes: Barcode[] = [];
  code: string;
  isQrEnabled: boolean = false;

  translations = {
    permission_missing_title: '',
    permission_missing_subtitle: '',
    login_success: '',
    permission_missing_txt: '',
    error: '',
    error_message: '',
    error_subheader_authentification: '',
    error_subheader_redirection: '',
    error_reason: ''
  };

  constructor(
    private api: ApiService,
    private http: HttpClient,
    private storage: StorageService,
    private alertService: AlertService,
    private translationService: TranslationService,
    private platform: Platform,
    public toastService: ToastService,
    public navController: NavController,
    public modalController: ModalController,
    private organisationService: OrganisationService,
    private apiService: ApiService,
    private helpdeskService: HelpdeskService,
    private errorManagerServiceService: ErrorManagerServiceService,
    private loaderService: LoaderService,
  ) {
    this.translationService.loadTranslation('login_service', this.translations);
  }

  public getLoginMethods(email: string) {
    let httpParams = new HttpParams()
      .set("email", email)

    return this.http.get(this.api.getHost() + '/authentication/login-methods', {
      params: httpParams
    });
  }

  public loginWithCredentials = async (email)  => {
    const pkceState = nanoid();
    const pkceCodeVerifier = nanoid(128);

    this.storage.setPkceState(pkceState);
    this.storage.setPkceCodeVerifier(pkceCodeVerifier);

    const sha256HashHex = await this.digestStringToSha256(pkceCodeVerifier);
    let codeChallenge = window.btoa(sha256HashHex);

    codeChallenge = codeChallenge
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');

    const params = {
      client_id: environment.oAuthClientId,
      redirect_uri: this.useNativeAuthRedirection() ? environment.nativeOAuthRedirectUri : environment.pwaOAuthRedirectUri,
      response_type: 'code',
      scope: '*',
      state: pkceState,
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
      prompt: '',
      email: email,
      source: this.getPlatform()
    };

    const queryParams = new URLSearchParams(params);
    const authorizeUrl = `${environment.oAuthHost}/oauth/authorize?${queryParams.toString()}`;

    await Browser.open({ url: authorizeUrl, presentationStyle : 'popover' });
  }

  public loginWithAzureSSO = async (email) => {
    OAuth2Client.authenticate(this.getAzureB2cOAuth2Options(this.storage.getAzureAppId(), this.storage.getAzureTenantId(), email)).then(response => {
      this.getAccessTokenFromAzure(response,() => {
        this.loaderService.showGateLoader();
        this.storage.setAzureToken(response["access_token"]);
        this.redirectAfterAuthentication()
      }, (reason) => {
        this.alertService.fullAlert(this.translations.error, this.translations.error_subheader_authentification, this.translations.error_reason + reason.message);
      });
    }).catch(reason => {
      this.alertService.fullAlert(this.translations.error, this.translations.error_subheader_authentification, this.translations.error_reason + reason.message);
    });
  }

  public loginWithCode(code: string, callbackSuccess, callbackError) {

    const codeData = code.split('|');

    const oauthData = {
      remember_token: codeData[1],
      user_id: codeData[0],
      grant_type: 'remember_token',
      client_id: environment.apiClientId,
      client_secret: environment.apiClientSecret
    };

    return this.callOauthToken(oauthData, callbackSuccess, callbackError);
  }

  public async loginWithQrCode(callbackSuccess, callbackError) {

    await BarcodeScanner.requestPermissions();

    // Check if the Google ML Kit barcode scanner is available
    await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable().then(async (data) => {
      if (data.available) {
        // Start the barcode scanner
        await this.startScanner().then(async (barcodes) => {
          this.code = barcodes[0].rawValue;
          return this.loginWithCode(this.code, callbackSuccess, callbackError);
        });
      } else {
        // Install the Google ML Kit barcode scanner
        await BarcodeScanner.installGoogleBarcodeScannerModule().then(async () => {
          await this.startScanner().then(async (barcodes) => {
            this.code = barcodes[0].rawValue;
            return this.loginWithCode(this.code, callbackSuccess, callbackError);
          });
        });
      }
    }).catch(() => {
      BarcodeScanner.checkPermissions().then((status)=> {
        if (status.camera === 'granted') {
          // The camera is visible behind the WebView, so that you can customize the UI in the WebView.
          // However, this means that you have to hide all elements that should not be visible.
          // You can find an example in our demo repository.
          // In this case we set a class `barcode-scanner-active`, which then contains certain CSS rules for our app.
          // document.querySelector('app')?.classList.add('barcode-scanner-active');
          (window.document.querySelector('ion-app') as HTMLElement).classList.add('cameraView');
          // Add the `barcodeScanned` listener
          const listener = BarcodeScanner.addListener(
            'barcodeScanned',
            async result => {
              this.closeQrLogin();
              return this.loginWithCode(result.barcode.rawValue, callbackSuccess, callbackError);
            },
          );
          this.isQrEnabled = true;
          // Start the barcode scanner
          BarcodeScanner.startScan();

        } else if (status.camera === 'denied') {
          // camera permission was permanently denied, go to settings
          this.alertService.fullAlert(
            this.translations.permission_missing_title,
            this.translations.permission_missing_subtitle,
            this.translations.permission_missing_txt
          ).then(() => {
            BarcodeScanner.openSettings();
          });
        } else {
          // permission was denied, but not permanently. You can ask for permission again at a later time.
          this.alertService.fullAlert(
            this.translations.permission_missing_title,
            this.translations.permission_missing_subtitle,
            this.translations.permission_missing_txt
          ).then(() => {
            BarcodeScanner.openSettings();
          });
        }
      })
    });
  }

  public logout = async () => {
    if(this.storage.getAzureAppId()){
       const url = `https://login.microsoftonline.com/${this.storage.getAzureTenantId()}/oauth2/v2.0/logout`;

      await OAuth2Client.logout(
        this.getAzureB2cOAuth2Options(this.storage.getAzureAppId(), this.storage.getAzureTenantId()),
        this.storage.getAzureToken() // only used on android
      ).then(() => {
         Browser.open({url}).then(() => {
          this.storage.cleanOAuth();
        });
      }).catch(reason => {
         this.alertService.fullAlert(this.translations.error, 'Microsoft logout failed', this.translations.error_reason + reason.message);
      });
    } else {
       this.storage.cleanOAuth();
    }
  }

  public isLogged() {
    return this.storage.getBearer() !== null && this.storage.getBearer().length > 0;
  }

  public getAzureB2cOAuth2Options(azureAppId, tenantId, email?): OAuth2AuthenticateOptions
  {
    return {
      appId: azureAppId,
      authorizationBaseUrl: `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize`,
      scope: "https://graph.microsoft.com/User.Read",
      accessTokenEndpoint: `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/token`,
      resourceUrl: "https://graph.microsoft.com/v1.0/me/",
      logoutUrl: `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/logout`,
      responseType: "code",
      pkceEnabled: true,
      logsEnabled: true,
      additionalParameters: {
        'login_hint': email
      },
      web: {
        redirectUrl: environment.pwaOAuthRedirectUri,
        windowOptions: "height=600,left=0,top=0",
      },
      android: {
        redirectUrl: "msauth://com.bepark.besaas/VzSiQcXRmi2kyjzcA%2BmYLEtbGVs%3D"
      },
      ios: {
        pkceEnabled: true, // workaround for bug #111
        redirectUrl: "msauth.com.bepark.besaas://auth"
      }
    };
  }

  public getAccessTokenFromAzure(response: string, callbackSuccess?, callbackError?) {

    const oauthData = {
      provider: 'azure',
      provider_user_id: response["id"],
      grant_type: 'sso',
      client_id: environment.apiAppClientId,
      client_secret: environment.apiAppClientSecret,
    };

    return this.callOauthToken(oauthData, callbackSuccess, callbackError);
  }

  public getAccessToken = async (authorizationCode, state, callbackSuccess, callbackError) => {
    const pkceState = this.storage.getPkceState();
    const pkceCodeVerifier = this.storage.getPkceCodeVerifier();

    const stateMatchSavedPkceState = state === pkceState;

    if (stateMatchSavedPkceState) {
      if (authorizationCode) {
        const response = this.http.post(
          `${environment.oAuthHost}/oauth/token`,
          {
            client_id: environment.oAuthClientId,
            grant_type: 'authorization_code',
            redirect_uri: this.useNativeAuthRedirection() ? environment.nativeOAuthRedirectUri : environment.pwaOAuthRedirectUri,
            code_verifier: pkceCodeVerifier,
            code: authorizationCode,
          }
        ).subscribe((response) => {
          this.storage.storeOAuthResponse(response);
          if (callbackSuccess !== null) {
            callbackSuccess(response);
          }
        }, (response) => {
          if (callbackError !== null) {
            callbackError(response);
          }
        });
      } else {
        throw new Error('Authorization Code is missing');
      }
    } else {
      throw new Error("Return state doesn't match save pkceState");
    }
  }

  private callOauthToken(oauthData, callbackSuccess?, callbackError?) {
    return this.http.post(this.api.getHost() + '/oauth/token', oauthData).subscribe((response) => {
      this.storage.storeOAuthResponse(response);
      if (callbackSuccess !== null) {
        callbackSuccess(response);
      }
    }, (response) => {
      if (callbackError !== null) {
        callbackError(response);
      }
    });
  }

  async startScanner() {
    const { barcodes } = await BarcodeScanner.scan({
      formats: [BarcodeFormat.QrCode, BarcodeFormat.Ean13]
    });
    return barcodes;
  }

  public closeQrLogin() {
    // Make all elements in the WebView visible again
    (window.document.querySelector('ion-app') as HTMLElement).classList.remove('cameraView');

    // Remove all listeners
    BarcodeScanner.removeAllListeners();

    // Stop the barcode scanner
    BarcodeScanner.stopScan();

    this.isQrEnabled = false;
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
  // This is equivalent to PHP function hash('sha256', $string, true);
  public digestStringToSha256 = async (string) => {
    const data = new TextEncoder().encode(string);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    // Digest are express as array of binary value, this will convert it to a string that could be convert to Base 64.
    return String.fromCharCode(...new Uint8Array(hashBuffer));
  }

  public useNativeAuthRedirection(): boolean {
    return !( this.platform.is('electron')
      || this.platform.is('pwa')
      || this.platform.is('mobileweb')
      || this.platform.is('desktop')
    );
  }

  public getPlatform = () : string  => {
    if (this.platform.is('android')) {
      return 'android';
    }
    if (this.platform.is('ios')) {
      return 'ios';
    }

    return 'pwa';
  };

  public redirectAfterAuthentication(){
    this.organisationService.getOrganisations().subscribe((organisationResponse: BesaasResponse) => {
      this.organisationService.switchOrganisation(organisationResponse.data[0].id);
      this.toastService.presentToast(this.translations.login_success);
      this.loaderService.hideGateLoader();
      this.navController.navigateForward(route.access);
    }, (organisationResponse) => {
      this.loaderService.hideGateLoader();
      this.alertService.fullAlert(this.translations.error, this.translations.error_subheader_redirection, this.translations.error_reason + organisationResponse.message);
      this.errorManagerServiceService.handle(organisationResponse, false);
    });
  }
}
