import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslationService } from './translation.service';

/**
 * Service to handle alert presentations in the application.
 *
 * @example
 * ```typescript
 * constructor(private alertService: AlertService) {}
 *
 * this.alertService.present('This is an alert message.');
 * this.alertService.fullAlert('Header', 'Subheader', 'This is a full alert message.');
 * this.alertService.uncaughtErrorAlert('This is an uncaught error.');
 * ```
 */
@Injectable({
  providedIn: 'root',
})
export class AlertService {
  translations = {
    uncaught_error_header: '',
    please_report: '',
  };

  constructor(
    private alertController: AlertController,
    private translationService: TranslationService
  ) {
    this.translationService.loadTranslation('error', this.translations);
  }

  /**
   * Presents an alert with the given message.
   *
   * @param message - The message to display in the alert.
   * @returns A promise that resolves when the alert is presented.
   */
  public async present(message: string): Promise<void> {
    const alert = await this.alertController.create({
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  /**
   * Presents an alert with the given message and header.
   *
   * @param header - The header to display in the alert.
   * @param subheader - The subheader to display in the alert.
   * @param message - The message to display in the alert.
   * @returns A promise that resolves when the alert is presented.
   */
  public async fullAlert(header: string, subheader: string, message: string): Promise<void> {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subheader,
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  /**
   * Presents an alert for an uncaught error.
   *
   * @param error - The error to display in the alert. Can be a string or an Error object.
   * @returns A promise that resolves when the alert is presented.
   */
  public async uncaughtErrorAlert(error: string | Error): Promise<void> {
    let message = this.translations.please_report + ' ';
    message += typeof error === 'string' ? error : error.message;
    const alert = await this.alertController.create({
      header: this.translations.uncaught_error_header,
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }
}
